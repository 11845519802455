/*
 * @Author: hai.zhang
 * @LastEditors: hai.zhang
 * @Date: 2020-07-10 17:08:44
 * @LastEditTime: 2020-07-22 20:40:23
 */ 
import request from '@/utils/request.js'
import response from '@/utils/response.js'
import authApi from './api.js'
/* global Vue */
const Loading = Vue.prototype.$loading
const $http = Vue.prototype.$http
const Message = Vue.prototype.$message

export function getAuth (store, router) {
  router.beforeEach(async (to, from, next) => {
    const fe = window.fe || {}
    fe.pageTime = new Date().getTime() + '' + parseInt(Math.random() * 1e4)
    const loadingOptionsDefault = {
      text: '拼命加载中',
      background: 'rgba(255, 255, 255, 0.8)',
      spinner: 'el-icon-loading'
    }
    const loadingInstance = Loading(loadingOptionsDefault)
    let loginCheck = to.meta.loginCheck !== undefined ? to.meta.loginCheck : true
    const authCheck = to.meta.authCheck !== undefined ? to.meta.loginCheck : true
    if (loginCheck) {
      let boolean = await getUserInfo(store, next, to)
      // 如果是已登录 获取权限
      if (store.state.user.userInfo.loginStatus && boolean) {
        // 是否校验权限
        if (authCheck) {
          await await getApptree(store, next, to)
          const allMenu = store.getters.allMenu
          if (allMenu && allMenu[to.path] && allMenu[to.path].name) {
            to.meta.title = allMenu[to.path].name
          }
          let code = (to.meta && to.meta.authCode) || to.path
          getPermlist(store, code, next)
        } else {
          // 如果不校验直接渲染
          next()
        }
      }
    } else {
      next()
    }
    loadingInstance.close()
  })
}

// 获取权限树
async function getApptree (store) {
  const promise = request($http, authApi.apptree)
  const res = await response(promise)
  let boolean = false
  if (res.isOk) {
    let data = res.content || []
  
    boolean = true
    let allMenu = []
    data.map((item) => {
      let menuList = item.menuList && item.menuList.map((menu) => {
        let minorList = menu.minorList && menu.minorList.map((subMenu) => {
          return {
            title: subMenu.menuName,
            code: subMenu.route[0] === '/' ? subMenu.route : '/' + subMenu.route
          }
        })
        return {
          title: menu.menuName,
          code: menu.menuId,
          router: menu.route,
          children: minorList
        }
      })
      let link = item.path
      // 如果有二级菜单，应用的link
      if (Array.isArray(item.menuList) && item.menuList.length) {
        if (item.menuList[0].minorList.length) {
          let childrenRoute = item.menuList[0].minorList[0].route
          if (childrenRoute[0] === '/') {
            link = link + '/#' + childrenRoute
          } else {
            link = link + '/#/' + childrenRoute
          }
        } else {
          let childrenRoute = item.menuList[0].route
          if (childrenRoute[0] === '/') {
            link = link + '/#' + childrenRoute
          } else {
            link = link + '/#/' + childrenRoute
          }
        }
      } else {
        link = link + '/#/'
      }
      allMenu.push(
        {
          applicationId: item.applicationId,
          title: item.applicationName,
          iconUrl: item.icon,
          unIconUrl: item.icon,
          childMenu: menuList,
          link: link
        }
      ) 
    })
    console.log(allMenu, 'allMenu')
    allMenu.unshift({
      title: '工作台',
      link: '/#/portal',
      iconUrl: '&#xe6f4;',
      unIconUrl: '&#xe6f4;'
    })
    store.commit('setAllMenu', allMenu)
    store.commit('setApplicationClassifyList', data)
    store.commit('setAuthRequestStatus', true)
    store.dispatch('setAppClassifyList', allMenu)
  } else {
    Message({
      type: 'error',
      message: '菜单获取失败，请刷新重试！'
    })
  }
  return boolean
}
// 获取权限点
async function getPermlist (store, code, next) {
  let parameter = {
    code: deleteDivider(code)
  }
  if (code === '/portal') {
    next()
    return 
  }
  const promise = request($http, authApi.permlist, parameter)
  const res = await response(promise)
  if (res.isOk) {
    // 如果没有权限 1代表无权限
    if (res.content && res.content.noPermission === 1) {
      next({ path: '/no-auth' })
    } else {
      next()
    }
  } else {
    Message({
      type: 'error',
      message: '无权操作'
    })
    next({ path: '/no-auth' })
  }
}

// 获取用户信息
async function getUserInfo (store, next, to) {
  const promise = request($http, authApi.getLoginUser)
  const res = await response(promise, {
    showError: false,
    authError: true,
    tokeInvalidError: false
  })
  let boolean = false
  if (res.isOk) {
    const data = res.content
    if (data) {
      data.loginStatus = true
      store.commit('setUserInfo', res.content)
      boolean = true
    }
  }
  return boolean
}

function deleteDivider (str) {
  return str[str.length - 1] === '/' ? str.slice(0, -1) : str
}
