<template>
  <div class="layout-full">
    <router-view></router-view>
  </div>
</template>

<script>
  import PHeader from 'components/p-header/header'
  export default {
    name: 'layout-full',
    components: {
      PHeader
    }
  }
</script>

<style scoped>
  .layout-full{
    width: 100%;
    height: 100%;
  }
</style>
