/**
 * Created by Sandon on 2019-03-27.
 */
export default {
  fixed: [
    {
      name: 'no-auth',
      path: '/no-auth',
      component: () => import(/* webpackChunkName: "no-auth" */'../routes/error/no-auth.vue'),
      meta: {
        title: '无权限',
        loginCheck: false
      }
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "404" */'../routes/error/not-find.vue'),
      meta: {
        title: '404',
        loginCheck: false
      }
    }
  ],
  fluid: [
  ]
}
