/*
 * @Author: hai.zhang
 * @LastEditors: hai.zhang
 * @Date: 2020-07-22 20:38:08
 * @LastEditTime: 2020-07-22 20:40:11
 */ 
import Vue from 'vue'
import Vuex from 'vuex'
import sidebarTags from './modules/sidebar-tags/index'
import user from './modules/user/index'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    sidebarTags,
    user
  }
})

export default store
