<template>
  <div class="layout-fixed">
    <p-header></p-header>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import PHeader from 'components/p-header/header'
  export default {
    name: 'layout-fixed',
    components: {
      PHeader
    }
  }
</script>

<style scoped>
  .layout-fixed{
    height: 100%;
  }
  .container {
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    min-height: calc(100vh - 32px);
    background-color: #fff;
  }
</style>
