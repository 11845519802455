/*
 * @Date: 2020-02-19 15:07:57
 * @LastEditTime : 2020-03-06 15:55:50
 * @LastEditors  : wang jun feng
 */
import promise from 'es6-promise'
// 引入promise
import assign from 'es6-object-assign'
// 引入object assign
promise.polyfill()
assign.polyfill()
const fe = window.fe || {}
let Vue

// 引入封装好的vue
if (fe.vue) {
  Vue = fe.vue
  // Vue.use(VueI18n)
} else {
  console.log('请引入sy-vue，地址：//static.sunyur.com/fe-common/modules/sy-vue/dist/index.js')
}
export default Vue
