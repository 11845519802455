<template>
  <div id="header" class="syue-clr">
		<div class="header-sunyur">
      <img src="//img-home.sunyur.com/167098426448833124.png" alt="">
		</div>
		<div class="header-user">
			<el-popover
				placement="bottom-end"
				title=""
				width="211"
				trigger="click"
				popper-class="header-user-outlogin-popper"
				>
				<div class="user-name" slot="reference"><i class="el-icon-more"></i></div>
				<div class="header-user-out-login syue-clr">
					<div class="user-img">
            <img :src="userInfo.avatar" alt="" v-if="userInfo.avatar">
            <img v-else src="//img.sunyur.com/158901328967081447.svg" alt="">
					</div>
					<div class="name-con">
						<p class="name">{{userInfo.name || 'admin'}}</p>
						<a href="/eai/logout" style="font-size: 12px;color: #333;">退出账号</a>
						<!-- <p class="welcome" @click="handleLoginOut">退出账号</p> -->
					</div>
				</div>
			</el-popover>
		</div>
  </div>
</template>

<script>
import memberApi from '@/apis/member.js'
import GetData from '@/utils/response.js'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    handleLoginOut () {
      const api = memberApi.loginOut
      let result = GetData(this.$http(api.type, api.url), {showError: true, loadingOptions: true})
      if (result.code === 'success' && result.success) {
        this.$router.push('/member/login')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/css/variable.scss';
#header {
	background: #0F44C6;
	line-height: 56px;
	.header-sunyur {
		float: left;
		width: 200px;
		height: 64px;
		img {
			display: block;
			height: 30px;
			margin: 0 auto;
			padding-top: 15px;
		}
	}
	.header-user {
		float: right;
		padding-right: 20px;
		color: #fff;
		.user-name {
			cursor: pointer;
			i {
				cursor: pointer;
				transform: rotate(90deg);
			}
		}
	}
}
</style>
<style lang="scss">
@import '../iconfont/iconfont.css';
.header-user-outlogin-popper {
	width: 211px;
	min-width: 211px;
	height: 68px;
	padding: 14px;
	box-sizing: border-box;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,0.16);
	border-radius: 3px;
	top: 40px !important;
	margin-top: 10px !important;
	.popper__arrow {
		display: none;
	}
	.header-user-out-login {
		float: left;
		width: 184px;
		height: 100%;
		box-sizing: border-box;
		.user-img {
			float: left;
			width: 40px;
			height: 40px;
			img {
				width: 100%;
				height: 100%;
        display: block;
        border-radius: 50%;
			}
		}
		.name-con {
			float: left;
			width: calc(100% - 48px);
			margin-left: 8px;
			.name {
				font-size: 12px;
				color: #333;
				line-height: 17px;
				margin-top: 2px;
				margin-bottom: 3px;
				font-weight: 600;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.welcome {
				line-height: 17px;
				font-size: 12px;
				color: #687082;
				cursor: pointer;
			}
		}
	}
}
</style>
