import Vue from '@/app'
import ListHeader from '@/components/list-header/index.vue'
import router from './router/index'
import store from './store/index'
import registerGlobalFilters from '@/common/filter/index.js'
import { ie9OninputPolyfill } from '../../../utils/ie9-oninput-polyfill'
import { getAuth } from '@/components/menu-auth/auth/index.js'
import {getQueryVal} from '@/utils'
/* eslint-disable*/
if (process.env.NODE_ENV === 'production') {
  __webpack_public_path__ = window.fe.staticUrl + '/fe-inner-op/'
} else{
  Vue.config.devtools = true
}

// 同步companyId
(() => {
  const companyId = getQueryVal('id')
  const {origin, hash, pathname} = window.location

  if (!companyId && hash.indexOf('/paas/') >= 0) {
    window.location.href = `${origin}${pathname}#/paas/tenant/list`
  }

  document.getElementById('companyId').value = companyId
})()

registerGlobalFilters(Vue)
// 针对IE9删除el-input输入框的字符，数据没有同步的polyfill
ie9OninputPolyfill()
/* eslint-disable no-new */
// 获取权限
getAuth(store, router)
Vue.component('list-header', ListHeader)
window.vueInstantiation = new Vue({
  router,
  store
}).$mount('#app')
