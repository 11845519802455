export default {
  fixed: [],
  fluid: [
    // 租户列表
    {
      name: 'portal',
      path: '/portal',
      component: () => import(/* webpackChunkName: "portal" */'../routes/portal/index.vue'),
      meta: {
        title: '工作台',
        cache: false,
        directlyCon: true
      }
    }
  ]
}
