// 权限接口
const auth = {
  // 权限接口
  permlist: {
    url: '/eai/role/currentUserResources',
    type: 'post'
  },
  // 菜单
  apptree: {
    url: '/eai/application/getUserApplication',
    type: 'get'
  },
  // 获取用户信息
  getLoginUser: {
    url: '/eai/getLoginUser',
    type: 'get'
  }
}
export default auth
