/*
 * @Author: hai.zhang
 * @LastEditors: hai.zhang
 * @Date: 2020-03-19 15:57:13
 * @LastEditTime: 2020-04-27 14:26:43
 */
export * from './transformDate'
export * from './url'
export * from './randomWord'
export const getQueryVal = function (name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

export function disabledDate (refer, action, type = 'timestamp', date) {
  if (!refer) {
    return false
  }
  if (type === 'date') {
    refer = new Date(`${refer} 00:00:00`).getTime()
  }
  if (action === '>') {
    return date > refer
  } else if (action === '<') {
    return date < refer
  } else if (action === '>=') {
    return date >= refer
  } else if (action === '<=') {
    return date <= refer
  }
  return false
}
