import LayoutFixed from 'pages/index/src/routes/layout-fixed.vue'
// import LayoutFluid from 'pages/index/src/layout-fluid'
import LayoutFluid from '@/components/menu-auth/layout-fluid/index.vue'
import LayoutFull from 'pages/index/src/routes/layout-full.vue'

import error from './error'
import member from './member'
import portal from './portal'
import system from './system'
// import businessConfig from './business-config'

const routes = [
  {
    path: '/',
    redirect: '/portal',
    meta: {
      loginCheck: false
    }
  },
  // 有侧边栏的布局
  {
    path: '/',
    component: LayoutFluid,
    children: [
      ...portal.fluid,
      ...system.fluid
    ]
  },
  // 没有侧边栏固定布局
  {
    path: '/',
    component: LayoutFixed,
    children: [
      ...portal.fixed,
      ...system.fixed
    ]
  },
  {
    path: '/',
    component: LayoutFull,
    children: [
      ...member.full
    ]
  },
  // 没有匹配到任何路由的错误处理，必须放到最后面
  {
    path: '/',
    component: LayoutFixed,
    children: [
      ...error.fixed
    ]
  }
]
/* eslint-disable */
const router = new VueRouter({
  routes
})
/* eslint-enable */

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  
  // 根路由暂时做一个跳转处理
  // if (to.path === '/') {
  //   router.push({
  //     path: '/portal'
  //   })
  // }
})
router.beforeEach((to, from, next) => {
  const fe = window.fe || {}
  fe.pageTime = new Date().getTime() + '' + parseInt(Math.random() * 1e4)
  window.fe && window.fe.spm && window.fe.spm(to, from)
  next()
})

export default router
