import state from './state'
import mutation from './mutation'
import action from './action'
import * as getters from './getters'

const store = {
  state: {
    ...state
  },
  mutations: {
    ...mutation
  },
  actions: {
    ...action
  },
  getters
}

export default store
