<template>
  <div class="layout-fluid-tabs-view">
    <el-tabs v-model="tabActive" type="border-card" @tab-remove="removeTab" @tab-click="handleTabChange" class="wrap-main-tab">
      <el-tab-pane
        v-for="item in tabs"
        :key="item.link"
        :label="item.label"
        :name="item.link"
        :closable="item.link !== '/portal'"
      >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    tabActive: {
      type: String
    }
  },
  data () {
    return {
      // tabActive: ''
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.sidebarTags.tabs
    })
  },
  methods: {
    ...mapActions([
      'delTabs'
    ]),
    removeTab (targetName) {
      const findIdx = this.tabs.findIndex(item => item.link === targetName)
      this.tabActive = this.tabs[findIdx - 1].link
      this.delTabs(targetName)
      this.$router.push(this.tabActive)
    },
    handleTabChange (tabEl) {
      this.$router.push(tabEl.name)
    }
  }
}
</script>

<style>

</style>