export default {
  fixed: [],
  fluid: [
    {
      name: 'system-user-manage',
      path: '/system/user/manage',
      component: () => import(/* webpackChunkName: "system-user-manage" */'../routes/system/user/manage/index.vue'),
      meta: {
        title: '用户管理'
      }
    },
    {
      name: 'system-role-list',
      path: '/system/role/list',
      component: () => import(/* webpackChunkName: "system-role-list" */'../routes/system/role/list/index.vue'),
      meta: {
        title: '角色权限'
      }
    },
    {
      name: 'system-role-set-power',
      path: '/system/role/set-power',
      component: () => import(/* webpackChunkName: "system-role-set-power" */'../routes/system/role/set-power/index.vue'),
      meta: {
        title: '设置权限',
        authCode: '/system/role/list'
      }
    },
    {
      name: 'system-app-manage',
      path: '/system/app/manage',
      component: () => import(/* webpackChunkName: "system-app-manage" */'../routes/system/system-config/app-manage/list/index.vue'),
      meta: {
        title: '应用管理'
      }
    },
    {
      name: 'system-app-manage-config-menu',
      path: '/system/app/manage/config-menu',
      component: () => import(/* webpackChunkName: "system-app-manage-config-menu" */'../routes/system/system-config/app-manage/config-menu/index.vue'),
      meta: {
        title: '配置菜单',
        authCode: '/system/app/manage'
      }
    },
    {
      name: 'system-app-manage-set-limit',
      path: '/system/app/manage/set-limit',
      component: () => import(/* webpackChunkName: "system-app-manage-config-menu" */'../routes/system/system-config/app-manage/set-limit/index.vue'),
      meta: {
        title: '定义权限',
        authCode: '/system/app/manage'
      }
    }
  ]
}
