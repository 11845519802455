const state = {
  // 侧边栏菜单
  sidebar: [
    // {
    //   title: '工作台',
    //   link: '/portal',
    //   iconUrl: '/159281371393480467.svg',
    //   unIconUrl: '/159281372528874178.svg'
    // },
    // {
    //   title: '租户订单',
    //   link: '/sales-order',
    //   iconUrl: '/15928137406032401.svg',
    //   unIconUrl: '/159281374788797097.svg',
    //   childMenu: [
    //     {
    //       title: '订单管理',
    //       code: 'order',
    //       children: [
    //         {
    //           title: '销售订单',
    //           code: '/sales-order',
    //           childLink: ['/sales-order/detail', '/sales-order/create']
    //         }
    //       ]
    //     },
    //     {
    //       title: '租户管理',
    //       code: 'tenement-manage',
    //       children: [
    //         {
    //           title: '租户服务',
    //           code: '/tenement',
    //           childLink: ['/tenement/detail', '/tenement/server-config', '/tenement/menu-auth']
    //         },
    //         {
    //           title: '菜单类型管理',
    //           code: '/menu-type',
    //           childLink: ['/menu-type/add']
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   title: '实施管理',
    //   link: '/application-manage',
    //   iconUrl: '/159281376033757946.svg',
    //   unIconUrl: '/159281376821734005.svg',
    //   childMenu: [
    //     {
    //       title: 'SAAS配置',
    //       code: 'SAAS',
    //       children: [
    //         {
    //           title: '应用管理',
    //           code: '/application-manage',
    //           childLink: ['/application-manage/permissions', '/application-manage/resources', '/application-manage/menu']
    //         },
    //         {
    //           title: '场景管理',
    //           code: '/classify-manage',
    //           childLink: ['/classify-manage/edit']
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   title: '开放平台',
    //   link: '/supply-config',
    //   iconUrl: '/159281377826025342.svg',
    //   unIconUrl: '/15928137894686397.svg',
    //   childMenu: [
    //     {
    //       title: '电商对接',
    //       code: 'dock-commerce',
    //       children: [
    //         {
    //           title: '电商配置',
    //           code: '/supply-config',
    //           childLink: ['/supply-config/edit']
    //         },
    //         {
    //           title: '服务管理',
    //           code: '/server-manage',
    //           childLink: ['/server-manage/create', '/server-manage/detail', '/server-manage/edit', '/server-manage/impower', '/server-manage/add']
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   title: '系统设置',
    //   link: '/system/user/manage',
    //   iconUrl: '/159281368331053165.svg',
    //   unIconUrl: '/159281369312676885.svg',
    //   childMenu: [
    //     {
    //       title: '权限管理',
    //       code: 'permission-manage',
    //       children: [
    //         {
    //           title: '用户管理',
    //           code: '/system/user/manage',
    //           childLink: []
    //         },
    //         {
    //           title: '角色权限',
    //           code: '/system/role/list',
    //           childLink: ['/system/role/set-power']
    //         }
    //       ]
    //     },
    //     {
    //       title: '系统配置',
    //       code: 'system-config',
    //       children: [
    //         {
    //           title: '应用管理',
    //           code: '/system/app/manage',
    //           childLink: ['/system/app/manage/config-menu']
    //         }
    //       ]
    //     }
    //   ]
    // }
  ],
  // 一级菜单选中项
  moduleActive: '',
  // 二级菜单列表
  menuList: [],
  // 页签
  tabs: [{
    label: '工作台',
    link: '/portal'
  }],
  // 将会被keep-alive缓存的页面name集合
  cachedTags: []
}
export default state
