const action = {
  // 添加tabs
  addTabs ({ dispatch }, view) {
    dispatch('addViewTabs', view)
    // dispatch('addCachedTabs', view)
  },
  // 更新tabs
  editTabs ({ commit }, view) {
    commit('EDIT_TAB_LIST', view)
    commit('EDIT_CHCHED_TABS', view)
  },
  // 删除tabs
  delTabs ({ commit }, view) {
    commit('DEL_TAB_LIST', view)
    commit('DEL_CHCHED_TABS', view)
  },
  addViewTabs ({ commit }, view) {
    commit('ADD_TAB_LIST', view)
  },
  addCachedTabs ({ commit }, view) {
    commit('ADD_CACHED_TABS', view)
  },
  setAppClassifyList ({ commit }, allMenu) {
    commit('SET_APP_CLASSIFY_LIST', allMenu)
  }
}

export default action
