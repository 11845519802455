/*
 * @Author: hai.zhang
 * @LastEditors: hai.zhang
 * @Date: 2020-04-27 14:24:10
 * @LastEditTime: 2020-04-27 21:03:30
 */
/**
 * Created by Sandon on 2019-05-21.
 */
import moment from 'moment'
import NP from 'number-precision'

export default function registerGlobalFilters (Vue) {
  Vue.filter('accuracyNumber', function (value, ratio = 2) {
    value = value || 0
    value = value.toString()
    let valueNumber = NP.round(value, ratio)
    // 如果存在小数点 精度转换为0时
    if (value.indexOf('.') !== -1) {
      let varArr = value.split('.')
      // 小数点位数大于精度位数
      if (varArr[varArr.length - 1].length > ratio && valueNumber === 0) {
        return value
      }
    }
    // 精度计算
    value = NP.round(value, ratio).toString()
    const index = value.indexOf('.')
    if (index === -1) {
      value += '.00'
    } else {
      const len = ratio - (value.length - 1 - index)
      for (let i = 0; i !== len; i++) {
        value += '0'
      }
    }
    return value
  })

  // 订单备注过滤imgurl
  Vue.filter('substituteValue', function (value) {
    const reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
    if (reg.test(value)) {
      return '-'
    } else {
      return value
    }
  })

  Vue.filter('convertTime', function (value, type) {
    if (value) {
      if (type) {
        return moment(value).format(type)
      } else {
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      return '-'
    }
  })

  Vue.filter('formatPrice', (value, ratio, emptyText = '0.00', padding = false) => {
    if (!value) {
      return emptyText
    } else if (ratio === undefined || ratio === null) {
      return value
    } else {
      const result = parseFloat(NP.round(value, ratio))
      return padding ? result.toFixed(ratio) : result
    }
  })

  Vue.filter('formatTaxRate', (taxRate, emptyText = '——') => {
    if (!taxRate) {
      return emptyText
    } else {
      return NP.times(taxRate, 100) + '%'
    }
  })

  Vue.filter('getMonthDay', function (value) {
    if (value) {
      return moment(value).format('YYYY-MM-DD')
    } else {
      return '-'
    }
  })
}
