const mutation = {
  // 设置用户信息
  setUserInfo: (state, userInfo) => {
    state.userInfo = userInfo
  },
  // 设置应用分类
  setApplicationClassifyList: (state, applicationClassifyList) => {
    state.applicationClassifyList = applicationClassifyList
  },
  // 设置全部菜单
  setAllMenu: (state, allMenu) => {
    state.allMenu = allMenu
  },
  // 设置当前页面权限
  setPageAuth: (state, pageAuth) => {
    state.pageAuth = pageAuth
  },
  // 设置权限请求状态
  setAuthRequestStatus: (state, boole) => {
    state.authRequestStatus = boole
  }
}
export default mutation
