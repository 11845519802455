<template>
  <div class="sidebar" ref="sidebar">
    <div class="sidebar-module">
      <div
        v-for="(item, idx) in sidebar"
        :key="idx"
        class="sidebar-module-item"
        :class="{'sidebar-module-item-active': moduleActive === item.link}"
        @click="handleModuleCheck(item)"
      >
        <div class="item-img">
          <!-- <img :src="moduleActive === item.link ? '//img.sunyur.com' + item.iconUrl : '//img.sunyur.com' + item.unIconUrl" alt=""> -->
          <span class="sy-iconfont application-icon" :class="moduleActive === item.link ? 'font-color-primary' : ''" v-html="item.iconUrl"></span>
        </div>
        <div class="item-text">{{item.title}}</div>
      </div>
    </div>
    <div class="sidebar-menu" v-if="showMenu">
      <el-menu
        :default-active="activeIndex"
        text-color="#232B3E"
        active-text-color=" #0F44C6"
        :unique-opened="false"
        router>
        <template v-for="item in menuList">
          <template v-if="item.children && item.children.length">
            <el-submenu :index="item.code" :key="item.code">
              <template slot="title">
                <span>{{item.title}}</span>
              </template>
              <el-menu-item
                v-for="childItem in item.children"
                :key="childItem.code"
                :index="childItem.code">
                {{childItem.title}}
              </el-menu-item>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item
              :index="item.code"
              :key="item.code"
            >
              <span slot="title">{{item.title}}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
      <!-- <div class="menu-section" v-for="item in menuList" :key="item.code">
        <div class="menu-section-title">
          <i class="el-icon-arrow-down"></i>
          {{item.title}}
        </div>
        <div class="menu-section-child">
          <div
            class="child-item"
            v-for="childItem in item.children"
            :key="childItem.code"
          >{{childItem.title}}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'

export default {
  props: {
    routerPath: {
      type: String
    },
    fluidStyle: {
      type: String
    }
  },
  data () {
    return {
      activeIndex: '',
      moduleActive: '/#/portal', // 默认工作台
      showMenu: false
    }
  },
  watch: {
    fluidStyle (newVal) {
      if (!newVal) {
        this.showMenu = true
      } else {
        this.showMenu = false
      }
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.sidebarTags.sidebar,
      menuList: state => state.sidebarTags.menuList
    })
  },
  mounted () {
    this.showMenu = !this.fluidStyle
    this.initSelect()
  },
  methods: {
    ...mapMutations({
      setMenuList: 'SET_MENU_LIST'
    }),
    // 菜单一级模块切换
    handleModuleCheck (item) {
      if (this.moduleActive === item.link) {
        return true
      }
      // 默认跳转第一个页面
      console.log(window.location.href)
      // 存储sessionStorage
      window.sessionStorage.setItem('route', this.moduleActive)
      if (item.link.indexOf('#')) {
        window.location.href = item.link
      } else {
        this.$router.push(item.link)
      }
      this.moduleActive = item.link
      // 保存当前模块的二级菜单数据
      this.setMenuList(item.childMenu || [])
      if (item.childMenu) {
        this.activeIndex = this.menuList[0].children[0].code
      }
    },
    getRouterFromLink (str) {
      if (!str) return ''
      str = str.substr(str.indexOf('#') + 1)
      if (str) {
        return str[str.length - 1] === '/' ? str.slice(0, -1) : str
      } else {
        return ''
      }
    },
    initSelect (cache) {
      let isMatch = false
      this.sidebar.map(moduleItem => {
        if (this.getRouterFromLink(moduleItem.link) === this.routerPath) {
          this.moduleActive = moduleItem.link
          this.setMenuList(moduleItem.childMenu)
          // 缓存路由
          window.sessionStorage.setItem('route', this.moduleActive)
          isMatch = true
          if (moduleItem.childMenu && moduleItem.childMenu.length) {
            moduleItem.childMenu.map((menuItem, menuIdx) => {
              menuItem.children.map((item, idx) => {
                if (item.code === this.routerPath) {
                  this.activeIndex = this.menuList[menuIdx].children[idx].code
                }
              })
            })
          }
        } else if (moduleItem.childMenu && moduleItem.childMenu.length) {
          moduleItem.childMenu.map((menuItem, menuIdx) => {
            menuItem.children.map((item, idx) => {
              if (item.code === this.routerPath || (item.childLink && item.childLink.indexOf(this.routerPath) >= 0)) {
                this.setMenuList(moduleItem.childMenu)
                // 缓存路由
                window.sessionStorage.setItem('route', this.routerPath)
                this.isMatch = true
                this.moduleActive = moduleItem.link
                this.activeIndex = this.menuList[menuIdx].children[idx].code
              }
            })
          })
        }
      })
      if (!isMatch) {
        this.routerPath = this.getRouterFromLink(window.sessionStorage.getItem('route'))
        if (this.routerPath && !cache) {
          this.initSelect(true)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/css/variable.scss';
.sidebar {
    width: 218px;
    background: #fff;
    float: left;
    font-size: 0px;
    // &:after {
    //   content: '';
    //   width: 10px;
    //   background-color: $default-color;
    //   display: block;
    //   position: absolute;
    //   left: 202px;
    //   top: 0;
    //   bottom: 0;
    // }
}
.sidebar-module {
  padding-top: 30px;
  display: inline-block;
  vertical-align: top;
  width: 72px;
  font-size: 12px;
  color: rgba(255,255,255,0.66);
  position: relative;
  z-index: 1;
  .sidebar-module-item {
    width: 100%;
    height: 64px;
    cursor: pointer;
    .item-img {
      width: 16px;
      height: 16px;
      margin: 0 auto;
      padding: 12px 0px 10px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .item-text {
      font-size: 12px;
      color: rgba(255,255,255,0.66);
      text-align: center;
    }
  }
  .sidebar-module-item-active {
    background: #fff;
    color: #222;
    .item-text {
      color: #222;
    }
  }
}
.sidebar-menu {
  display: inline-block;
  vertical-align: top;
  width: 146px;
  font-size: 14px;
  // menu样式覆盖
  ::v-deep .el-menu {
    border: none;
  }
  // ::v-deep .el-menu .el-submenu {
  //   padding: 0px;
  // }
  ::v-deep .el-menu .el-submenu__title {
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    margin: 8px 0px;
    cursor: pointer;
    padding-left: 29px !important;
    padding-right: 10px;
    .el-submenu__icon-arrow {
      left: 12px;
      right: auto;
    }
  }
  ::v-deep .el-menu .el-menu-item {
    height: 32px;
    line-height: 32px;
    padding-left: 29px !important;
    padding-right: 10px;
    cursor: pointer;
    min-width: 86px;
  }
}

</style>