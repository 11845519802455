
<template>
  <div class="wrap">
    <p-header></p-header>
    <div class="container-fluid" :class="fluidStyle === 'portal' ? 'portal-fluid-style' : ''">
      <sidebar ref="aside" :routerPath.sync="routerPath" :fluidStyle.sync="fluidStyle"></sidebar>
      <div class="main" ref="main">
        <!-- <tabs-view :tabActive.sync="tabActive"></tabs-view> -->
        <div class="main-content-wrap">
          <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive :include="cachedTags" :max="10"> -->
              <router-view/>
            <!-- </keep-alive> -->
          </transition>
        </div>
        <p style="font-size: 12px;color: #B3B8C3;text-align:center;padding-top: 14px;">京ICP备18060093-1号 京公网安11010802031064号 北京商越网络科技有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
  import PHeader from './components/header'
  import Sidebar from './components/sidebar'
  import TabsView from './components/tabs-view'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'layout-fluid',
    components: {
      PHeader,
      Sidebar,
      TabsView
    },
    data () {
      return {
        fluidStyle: '',
        tabActive: '/portal',
        tabsArr: [
          {
            label: '工作台',
            link: '/portal',
            closable: false
          }
        ]
      }
    },
    computed: {
      ...mapState({
        sidebar: state => state.sidebarTags.sidebar,
        tabs: state => state.sidebarTags.tabs,
        cachedTags: state => state.sidebarTags.cachedTags,
        menuList: state => state.sidebarTags.menuList
      }),
      routerPath () {
        let arr = this.$route.path.split('')
        // 适配路由末尾带/而无法展开默认
        let path = arr[arr.length - 1] === '/' ? arr.slice(0, arr.length - 1).join('') : this.$route.path
        return path
      }
    },
    mounted () {
      if (this.$route.meta.directlyCon) {
        this.fluidStyle = 'portal'
      } else {
        this.fluidStyle = ''
      }
      this.linkTabChange(this.$route)
    },
    watch: {
      $route (to, from) {
        if (to.meta.directlyCon) {
          this.fluidStyle = 'portal'
        } else {
          this.fluidStyle = ''
        }
        this.$nextTick(() => {
          this.$refs.aside.initSelect()
          this.linkTabChange(to)
        })
      }
    },
    methods: {
      ...mapActions([
        'addTabs'
      ]),
      // 处理页面跳转时页签改变
      linkTabChange (route) {
        const find = this.tabs.find(item => item.link === route.path)
        if (find) {
          this.tabActive = find.link
        } else {
          // 循环menuList二级菜单
          for (let index = 0; index < this.menuList.length; index++) {
            // 循环当前二级菜单下的三级菜单，同时也是tab的url
            for (let idx = 0; idx < this.menuList[index].children.length; idx++) {
              // 如果找到与当前url匹配的菜单code
              const currentMenu = this.menuList[index].children[idx]
              const currentMenuCode = currentMenu.code
              if (currentMenuCode === route.path || (currentMenu.childLink && currentMenu.childLink.indexOf(route.path) >= 0)) {
                // 如果tab里面已经有当前菜单，切换tab选中的页签，结束循环
                if (this.tabs.find(item => item.link === currentMenuCode)) {
                  this.tabActive = currentMenuCode
                  return true
                } else {
                  let obj = {
                    label: currentMenu.title,
                    link: currentMenuCode,
                    name: route.name
                  }
                  // 如果tab里面没有当前菜单，更新tab，同时更新缓存
                  this.addTabs(obj)
                  this.tabActive = currentMenuCode
                  return true
                }
              }
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/common/css/variable.scss';
  .wrap {
    height: 100%;
    min-height: 100vh;
    min-width: 100%;
  }
  .container-fluid {
    min-width: 100%;
    min-height: calc(100vh - 64px);
    background-color: #fff;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      width: 72px;
      background: #32394D;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
    .main {
      margin-left: 218px;
      background: #f1f3f6;
      overflow: hidden;
      min-height: calc(100vh - 64px);
      height: 100%;
      padding-bottom: 16px;
      box-sizing: border-box;
      .main-content-wrap {
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
        >div {
          background-color: #fff;
        }
        >div.main-content-wrap-no-bg {
          background-color: transparent;
        }
      }
      ::v-deep .wrap-main-tab {
        background-color: transparent;
        box-shadow: none;
        .el-tabs__content {
          padding: 0px;
        }
        .el-tabs__header {
          background-color: #0F44C6;
          .el-tabs__nav-prev, .el-tabs__nav-next {
            color: #fff;
            line-height: 30px;
          }
        }
        .el-tabs__nav, .el-tabs__item {
          height: 30px;
          line-height: 30px;
        }
        .el-tabs__item {
          background: #446DD0;
          font-size: 12px;
          color: #F5F7FC;
          &:hover {
            color: #F5F7FC;
          }
        }
        .is-active {
          background: #ECEEF3;
          font-size: 12px;
          color: #0F44C6;
          &:hover {
            color: #0F44C6;
          }
        }
      }
    }
  }
  .portal-fluid-style {
    .sidebar {
      width: 72px;
    }
    .main {
      margin-left: 72px;
    }
  }
  .sy-layout-fluid-content-fill-page {
    min-height: calc(100vh - 158px);
  }
</style>
<style lang="css">
body,
html {
  min-width: 1200px;
}
</style>
