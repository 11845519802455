<template>
  <div class="sunyur-list-header">
    <div class="header-title syue-clr" v-if="title">
      <span class="title-text">{{title}}</span>
      <div class="title-btn">
        <slot name="btn"></slot>
      </div>
    </div>
    <div class="header-tabs" v-if="isTabs">
      <el-tabs v-model="activeName">
        <slot></slot>
      </el-tabs>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isTabs: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeName: ''
    }
  },
  mounted () {
    if (this.activeTab) {
      this.activeName = this.activeTab
    }
  },
  watch: {
    activeName (newVal) {
      this.$emit('tabChange', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-title {
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  line-height: 50px;
  background: #f1f3f6;
  // box-shadow: 0 1px 4px 0 rgba(0,0,0,0.10);
  font-size: 16px;
  color: #222222;
  font-weight: 600;
  .title-text {
    float: left;
  }
  .title-btn {
    float: right;
  }
}
.header-tabs {
  padding: 0 20px;
}
</style>
<style lang="scss">
.sunyur-list-header .header-tabs .el-tabs {
  .el-tabs__nav-wrap {
    &::after {
      background-color: #F3F3F3;
    }
  }
  .el-tabs__item {
    font-size: 14px;
    color: #333333;
  }
  .is-active {
    color: #173EAE;
  }
  .el-tabs__active-bar {
    background-color:  #173EAE;
  }
  .el-tabs__header {
    margin-bottom: 20px;
  }
}
</style>