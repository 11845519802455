/**
 * Created by Sandon on 2019-03-27.
 */
export default {
  full: [
    {
      name: 'member-login',
      path: '/member/login',
      component: () => import(/* webpackChunkName: "member-login" */'../routes/member/index.vue'),
      meta: {
        title: '登录',
        loginCheck: false
      }
    }
  ],
  fluid: [
  ]
}
