const mutation = {
  // 设置应用分类
  SET_APP_CLASSIFY_LIST: (state, allMenu) => {
    state.sidebar = allMenu
  },
  // 设置菜单列表
  SET_MENU_LIST: (state, menu) => {
    state.menuList = menu
  },
  // 设置tab列表
  ADD_TAB_LIST: (state, tabs) => {
    if (state.tabs.some(item => item.link === tabs.link)) return
    state.tabs.push(
      Object.assign({}, tabs)
    )
  },
  // 更新tab列表
  EDIT_TAB_LIST: (state, tabs) => {
    const newArr = state.tabs.map(item => {
      if (item.link === tabs.editLink) {
        return {
          ...item,
          ...tabs.view
        }
      } else {
        return item
      }
    })
    state.tabs = newArr
  },
  // 删除tabs
  DEL_TAB_LIST: (state, name) => {
    const index = state.tabs.findIndex(item => item.link === name)
    index > -1 && state.tabs.splice(index, 1)
  },
  // 设置页面缓存列表
  ADD_CACHED_TABS: (state, tabs) => {
    if (state.cachedTags.includes(tabs.name)) return
    state.cachedTags.push(tabs.name)
  },
  // 更新页面缓存列表
  EDIT_CHCHED_TABS: (state, tabs) => {
    if (state.cachedTags.includes(tabs.name)) return
    state.cachedTags.push(tabs.name)
  },
  // 删除页面缓存列表
  DEL_CHCHED_TABS: (state, tabs) => {
    const index = state.cachedTags.indexOf(name)
    index > -1 && state.cachedTags.splice(index, 1)
  },
  // 设置module选中项
  SET_MODULE_ACTIVE: (state, moduleActive) => {
    state.moduleActive = moduleActive
  }
}
export default mutation
