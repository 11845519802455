const state = {
  // 用户信息
  userInfo: {
    loginStatus: false
  },
  // 权限状态
  authRequestStatus: false,
  // 应用分类列表
  applicationClassifyList: [],
  // 所有菜单
  allMenu: {},
  // 当前页面菜单
  pageAuth: {}
}
export default state
