// 会员相关接口
const member = {
  // 登录相关接口
  login: {
    // 账号密码登录
    pwLogin: {
      url: '/tenant/login',
      type: 'post',
      parameter: {
        username: String,
        password: String
      }
    }
  },
  // 获取用户信息
  getLoginUser: {
    url: '/eai/getLoginUser',
    type: 'get'
  },
  // 退出登录
  loginOut: {
    url: '/eai/logout',
    type: 'get'
  },
  // 菜单权限
  apptree: {
    url: '/eai/application/getUserApplication',
    type: 'get'
  }
}
export default member
